<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>医患管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/setDoctor' }">医生管理</el-breadcrumb-item>
      <el-breadcrumb-item>添加患者</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="add">
      <el-button @click="addCase">添加</el-button>
      <el-input
        placeholder="请输入精确的姓名或电话号"
        clearable
        prefix-icon="el-icon-search"
        v-model="search"
        style="width: 15%;margin-left: 20px;"
      >
      </el-input>
      <el-button type="primary" style="margin-left:20px" @click="query">查询</el-button>
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column prop="id" v-if="false"></el-table-column>
      <el-table-column label="姓名" prop="Cust_Name"></el-table-column>
      <el-table-column label="年龄" prop="Cust_Age"></el-table-column>
      <el-table-column label="性别">
        <template slot-scope="scope">
          {{ scope.row.Cust_Sex == 0 ? "男" : "女" }}
        </template>
      </el-table-column>
      <el-table-column label="身高" prop="Cust_Height"></el-table-column>
      <el-table-column label="体重" prop="Cust_Weight"></el-table-column>
      <el-table-column label="疾病" prop="MWH_illness"></el-table-column>
      <!-- <el-table-column>
                <template slot-scope="scope">
                    <div class="opt doc">
                        <a @click="jcbd(scope.row)">解除绑定</a>
                    </div>
                </template>
            </el-table-column> -->
    </el-table>
    <p class="paging">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="current"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalCount"
        :hide-on-single-page="value"
      ></el-pagination>
    </p>
    <!-- 搜索患者框 -->
    <el-dialog title="搜索患者" :visible.sync="pat">
      <el-form style="width: 80%;">
        <el-form-item label="请搜索患者:" label-width="100px">
          <el-input
            v-model="patname"
            placeholder="请输入患者的姓名、电话号或客户编号"
            autocomplete="off"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="pat = false">取 消</el-button>
        <el-button type="primary" @click="addsub">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 患者信息 -->
    <el-dialog title="患者信息" class="patmsg" :visible.sync="patmsg">
      <el-table :data="patlist">
        <el-table-column label="选择" width="50">
          <template slot-scope="scope">
            <el-radio
              v-model="radio"
              :label="scope.row.Cust_ID"
              @change="changepat(scope.row.Cust_ID)"
            >
              <i></i>
            </el-radio>
          </template>
        </el-table-column>
        <el-table-column property="Cust_Name" label="姓名"></el-table-column>
        <el-table-column property="Cust_Age" label="年龄"></el-table-column>
        <el-table-column label="性别">
          <template slot-scope="scope">
            {{ scope.row.Cust_Sex == 0 ? "男" : "女" }}
          </template>
        </el-table-column>
        <el-table-column property="Cust_Height" label="身高"></el-table-column>
        <el-table-column property="Cust_Weight" label="体重"></el-table-column>
        <el-table-column property="MWH_illness" label="疾病"></el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button
          @click="
            patmsg = false;
            cust = {};
          "
          >关 闭</el-button
        >
        <el-button type="primary" @click="bind">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  inject: ["reload"],
  data() {
    return {
      doc_id: "",
      list: [],
      current: 1, //默认显示第几页
      totalCount: 0, // 总条数，根据接口获取数据长度
      pageSizes: [20], // 个数选择器（可修改）
      PageSize: 20, // 默认每页显示的条数（可修改）
      value: false,
      search: "",
      pat: false,
      patname: "",
      patmsg: false,
      cust: {},
      patlist: [],
      radio: null,
      x_cust_id: "",
    };
  },
  mounted() {
    this.doc_id = this.$route.query.id;
    this.getdata(1);
  },
  methods: {
    getdata(page) {
      this.axios
        .get(
          "/gu/get_gu_custs_by_doc?doc_id=" +
            this.doc_id +
            "&page=" +
            page +
            "&size=" +
            this.PageSize
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.totalCount = res.data.count;
            this.list = res.data.result;
          } else {
            this.totalCount = 0;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSizeChange() {},
    handleCurrentChange(currentPage) {
      this.getdata(currentPage);
    },
    addCase() {
      //添加按钮事件
      this.pat = true;
    },
    query() {
      //搜索
      this.axios
        .post(
          "/gu/search_gu_custs",
          this.qs.stringify({ keyword: this.search, doc_id: this.doc_id })
        )
        .then((res) => {
          if (res.data.code == 0) {
            this.totalCount = res.data.result.length;
            this.list = res.data.result;
          } else {
            this.$message({
              type: "error",
              message: "没有查到该患者",
            });
            // this.list = [];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addsub() {
      //查询
      this.axios
        .post("/gu/search_gu_custs", this.qs.stringify({ keyword: this.patname }))
        .then((res) => {
          if (res.data.code == 0) {
            this.pat = false;
            this.patlist = res.data.result;
            if (res.data.result.length == 1) {
              this.radio = res.data.result[0].Cust_ID;
              this.x_cust_id = res.data.result[0].Cust_ID;
            } else {
              this.x_cust_id = "";
            }
            this.patmsg = true;
            this.search = "";
            this.patname = "";
          } else {
            this.$message({
              type: "error",
              message: "没有查到该患者",
            });
            // this.patlist = [];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    jcbd(row) {
      //解除绑定
    },
    changepat(Cust_ID) {
      this.x_cust_id = Cust_ID;
    },
    bind() {
      //绑定
      if (this.x_cust_id == "") {
        this.$alert("请选择一个患者", "提示", {
          confirmButtonText: "确定",
        });
      } else {
        // console.log({ x_cust_id: this.x_cust_id, doc_id: this.doc_id })
        this.$confirm("此操作将绑定医患关系, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.axios
              .post(
                "/gu/update_gu_cust_info_docid",
                this.qs.stringify({ x_cust_id: this.x_cust_id, doc_id: this.doc_id,uid:sessionStorage.getItem("servicer_id") })
              )
              .then((res) => {
                if (res.data.code == 0) {
                  // this.axios
                  //   .post(
                  //     "/field/update_z_newseadoc_main_docid_by_custid",
                  //     this.qs.stringify({
                  //       doc_id: this.doc_id,
                  //       cust_id: this.x_cust_id,
                  //       // uid: sessionStorage.getItem("servicer_id"),
                  //     })
                  //   )
                  //   .then((res) => {
                  //     if (res.data.code == 0) {
                  //       console.log(res);
                  //     }
                  //   });
                  this.$message({
                    type: "success",
                    message: "绑定成功",
                  });
                  this.patmsg = false;
                  this.x_cust_id = "";
                  this.reload();
                } else {
                  this.$message(res.data.msg);
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "取消绑定",
            });
          });
      }
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}

.opt a {
  cursor: pointer;
  color: green;
  margin-right: 20px;
  display: inline-block;
}

.add {
  width: 100%;
  height: 50px;
  text-align: left;
}
</style>
